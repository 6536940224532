import React from 'react';
import { APIReferenceItems } from '../utils/APIReference';
import ReferencePage from '../components/common/ReferencePage';
export { getServerData } from './index';

const APIReference: React.FC = () => {
  return (
    <ReferencePage
      title="References"
      description="Manage your data, your vaults, and your account with Skyflow's APIs."
      items={APIReferenceItems}
    ></ReferencePage>
  );
};

export default APIReference;
