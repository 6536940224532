import PRIMARY from '../../static/images/managementApi.svg';
import SECONDARY from '../../static/images/dataApi.svg';
import TERTIARY from '../../static/images/connectionApi.svg';
import ROUTES from '../docs-config/src';

export const APIReferenceItems = [
  {
    title: 'Data API',
    description: 'Insert and interact with data in your vaults.',
    image: SECONDARY,
    link: ROUTES.apiReference.record,
  },
  {
    title: 'Management API',
    description:
      'Control your account and schemas, including workspaces, vaults, users, and more.',
    image: PRIMARY,
    link: ROUTES.apiReference.management,
  },
  {
    title: 'Detect API',
    description:
      'Detect and de-identify sensitive data in strings and files.',
    image: TERTIARY,
    link: ROUTES.apiReference.detect,
  },
];
